export var rules = {
  name: [
    { required: true, message: '输入合同名称', trigger: 'blur' }
  ],
  contractType: [
    { required: true, message: '请选择合同类型', trigger: 'blur' }
  ],
  mySubject: [
    { required: true, message: '请选择我方签约主体', trigger: 'blur' }
  ],
  mySubjectCode: [
    { required: true, message: '请选择我方签约主体', trigger: 'blur' }
  ],
  businessType: [
    { required: true, message: '请选择业务类型', trigger: 'blur' }
  ],
  jpersonincharge: [
    { required: true, message: '请输入签约负责人', trigger: 'blur' }
  ],
  subject: [
    { required: true, message: '请输入供应商签约主体', trigger: 'blur' }
  ],
  medium: [
    { required: true, message: '请选择合同介质', trigger: 'change' }
  ],
  signature: [
    { required: true, message: '请选择签章方式', trigger: 'change' }
  ],
  associatedOrder: [
    { required: true, message: '请选择关联订单', trigger: 'blur' }
  ],
  strStartDate: [
    { required: true, message: '请选择合同生效日期', trigger: 'change' }
  ],
  strEndDate: [
    { required: true, message: '请选择合同生效日期', trigger: 'blur' }
  ],
  contractEffective: [
    { required: true, message: '请输入合同有效期,例如:1年', trigger: 'blur' }
  ],
  file: [
    { required: true, message: '请上传合同文件', trigger: 'blur' }
  ],
  supplierIsSignature: [
    { required: true, message: '请选择供应商是否签章', trigger: 'change' }
  ]
}

export var typedata = [{
  value: '1',
  label: 'PO'
},
{
  value: '2',
  label: '框架合同'
},
{
  value: '3',
  label: '订购合同'
},
{
  value: '3',
  label: '非标合同'
}]
export var quotaOptions = [
  {
    value: '1',
    label: '生产物料类'
  },
  {
    value: '2',
    label: '资产类'
  },
  {
    value: '3',
    label: '服务类'
  },
  {
    value: '4',
    label: '基建类'
  },
  {
    value: '4',
    label: '委托采购'
  },
  {
    value: '4',
    label: '其它协议'
  }
]
export var signData = [
  {
    value: '1',
    label: '曙光'
  },
  {
    value: '2',
    label: '宁畅'
  },
  {
    value: '3',
    label: '可控'
  }
]
