<template>
  <div>
    <div class="mainform">
      <div class="mainHeader">合同{{form.number}}
        <span @click="back" class="back">返回></span>
      </div>
      <div class="form">
        <el-form class="content bascform" ref="form" :class="{disabled:formDisabled}" :disabled="formDisabled"
          :rules="rules" :model="form" label-width="140px">
          <div class="col col4">
            <el-form-item label="合同编号">
              <span class="onlyText">
                {{form.number}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="合同类型" prop="contractType">
              <el-select v-model="form.contractType" placeholder="请选择">
                <el-option v-for="(item,index) in contractTypeOption" :key="index" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="合同名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="业务类型" prop="businessType">
              <el-select v-model="form.businessType" placeholder="请选择">
                <el-option v-for="item in businessTypeOption" :key="item.label" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="我方签约主体" prop="mySubject">
              <div class="flex-table">
                <el-form-item>
                  <el-select @change="ScopeChange" v-model="form.mySubject">
                    <el-option :key="index" v-for="(itme,index) in businessScopeList" :label="itme.companyDesc"
                      :value="itme.businessScope"></el-option>
                  </el-select>
                </el-form-item>
                <div class="flex-table-row">
                  <el-form-item prop="mySubjectCode">
                    <el-select @change="companyChange" v-model="form.mySubjectCode">
                      <el-option :key="index" v-for="(itme,index) in companyCodeList" :label="itme.companyCode"
                        :value="itme.companyCode"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="合同生效期" required>
              <div style="display:flex">
                <el-form-item prop="strStartDate">
                  <el-date-picker type="date" placeholder="选择日期" v-model="form.strStartDate" @change="timeForm"
                    style="width: 100%;"></el-date-picker>
                </el-form-item>
                -
                <el-form-item prop="strEndDate">
                  <el-date-picker type="date" placeholder="选择日期" v-model="form.strEndDate" @change="timeFormEnd"
                    style="width: 100%;"></el-date-picker>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="签约负责人" prop="jpersonincharge">
              <el-input v-model="form.jpersonincharge"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商签约主体">
              <span class="onlyText">
                {{form.subjectCode+'-'+form.subject}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" v-if="isShowOrderinfo">
            <el-form-item label="关联订单" prop="associatedOrder">
              <el-input v-model="form.associatedOrder">
                <template #append>
                  <el-button type="text" @click="chooseOrder">选择订单</el-button>
                </template>
              </el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="合同介质" prop="medium">
              <el-radio-group v-model="form.medium">
                <el-radio :label="0">纸质</el-radio>
                <el-radio :label="1">电子合同</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="col col4" v-if="ifSignature">
            <el-form-item label="签章方式" prop="signature">
              <el-radio-group v-model="form.signature">
                <el-radio :label="0">签约平台签章</el-radio>
                <el-radio :label="1">用印平台签章</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="供应商是否签章" prop="supplierIsSignature">
              <el-radio-group v-model="form.supplierIsSignature">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <!-- 状态为待确认 (供应商提交的合同) -->
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="状态">
              <span class="onlyText">
                {{form.status==0?'待审核':form.status==1?'驳回':form.status==2?'待签章':form.status==3?'待他签'
                :form.status==4?'已归档':form.status==6?'作废':form.status==7?'待确认':form.status==8?'待归档': '草稿'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" :class="{isreadonly:formDisabled}">
            <el-form-item label="合同文件" prop="file">
              <el-upload :action="actionURL" :on-preview="handlePreview" :on-remove="handleRemove"
                :before-remove="beforeRemove" :headers="tokenHeader" :on-success="handleSuccess" multiple
                :on-exceed="handleExceed" :file-list="form.file">
                <el-button v-if="!formDisabled" size="small" type="primary" plain>上传合同文件</el-button>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>
        <approval-mind></approval-mind>
        <dialog-box ref="addDialog" :orderData="orderData" dialogWidth="826px" :dialogShow="dialogShow"
          @handleClose='handleClose' @handleAffirm="handleAffirm" title="关联订单"
          :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'handleAffirm', type: 'primary' }]"
          componentName="orderdialogBody"></dialog-box>
        <!-- 1 驳回 -->
        <div class="action" v-if="form.status == 1">
          <el-button type="primary" size="medium" @click="editContract">编辑</el-button>
          <el-button type="primary" size="medium" @click="Abandoned">作废</el-button>
        </div>
        <!-- 5 草稿 -->
        <div class="action" v-if="form.status == 5">
          <el-button type="primary" size="medium" @click="editContract">编辑</el-button>
          <el-button type="primary" size="medium" @click="submitBtn">提交</el-button>
        </div>
        <!-- differentiatedContract 0 采购  1供应商  status 7 待确认-->
        <div class="action" v-if="(form.status == 7 || form.status == 0) && form.differentiatedContract == 1"
          :disabled="formDisabled">
          <el-button type="primary" size="medium" @click="submitBtn">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestForm, exportForm, request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { MIME } from '@/assets/js/mime.js'
import dayjs from 'dayjs'
import { rules } from './js/confirmationContract'
import { baseHost } from '@/assets/js/PublicData.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'ConfirmationContract',
  data: function () {
    FlowCtlData.docid = ''
    return {
      disabled: true,
      showPattern: false, // 签章方式是否显示
      // form: formData,
      form: {},
      businessTypeOption: [
        { label: '生产物料类', value: '1' },
        { label: '资产类', value: '2' },
        { label: '服务类', value: '3' },
        { label: '基建类', value: '4' },
        { label: '委托采购', value: '5' },
        { label: '其它协议', value: '6' }
      ],
      contractTypeOption: [
        { label: '订单', value: '1' },
        { label: '框架合同', value: '2' },
        { label: '订购合同', value: '3' },
        { label: '非标合同', value: '4' }
      ],
      businessScopeList: [],
      companyCodeList: [],
      formDisabled: true,
      rules: rules,
      orderData: [],
      dialogShow: false,
      isShowOrderinfo: true,
      ifSignature: false, // 签章方式
      startDate: dayjs(new Date(new Date().getTime() - 31536000000)).format('YYYY-MM-DD'),
      endDate: dayjs(new Date()).format('YYYY-MM-DD'),
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      materialURL: baseHost.SupplierRegHost + '/api/bid/upload/material'
    }
  },
  created () {
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.businessScopeList = response
    })
    this.contractDetails()
    const id = this.$route.query.id
    FlowCtlData.flowid = 'ContractAward'
    FlowCtlData.docid = id
    FlowCtlData.initialize()
  },
  watch: {
    $route: {
      handler () {
        const id = this.$route.query.id
        this.contractDetails()
        FlowCtlData.flowid = 'ContractAward'
        FlowCtlData.docid = id
        FlowCtlData.initialize()
      }
    },
    form: {
      handler: function (data) {
        if (data.medium === 1) {
          this.ifSignature = true
        } else {
          this.ifSignature = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 我方签约主体
    chooseOrder () { // 选择订单按钮
      const obj = {
        providerCode: this.form.subjectCode, // 供应商
        companyCode: this.form.mySubjectCode
      }
      request('/api/order/order/getOrderListByUserId', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.orderData = res.data
        }
      })
      this.dialogShow = true
    },
    handleAffirm () {
      var newListData = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
      if (newListData.length > 0) {
        var newList = []
        newList = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
        newList.forEach(item => {
          this.form.associatedOrder = this.form.associatedOrder + item.orderCode + ','
        })
        this.form.associatedOrder = this.form.associatedOrder.substring(0, this.form.associatedOrder.length - 1)
        // this.isShowOrderinput = true
      } else {
        this.$message({
          message: '请选择要关联的订单',
          type: 'warning'
        })
      }
      this.dialogShow = false // 确认按钮
    },
    contractDetails () {
      const id = this.$route.query.id
      if (id) {
        requestForm('/api/pact/contract/queryOne?id=' + id, 'post').then((res) => {
          if (res.code === '200') {
            this.form = res.data
            this.form.strStartDate = this.form.startDate
            this.form.strEndDate = this.form.endDate
            if (this.form.status === 7 && this.form !== '') {
              this.formDisabled = false
            }
          }
          this.ScopeChange()
          FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.PurchasingManager = val })
          FlowCtlData.getApprPsn('560667585021624320').then((val) => { this.form.legal = val })
        })
      }
    },
    submitBtn: function () { // 提交
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.form.status = 0
      const id = this.$route.query.id
      FlowCtlData.getNodeAction('TJ', this.form).then((val) => {
        if (val) {
          FlowCtlData.alertDialog(this).then(() => {
            FlowCtlData.setFormData(FlowCtlData, this.form)
            requestForm('/api/pact/contract/updateStatus?' + id, 'POST', this.form).then((response) => {
              if (response.code === '200') {
                FlowCtlData.docid = Response.data
                FlowCtlData.setNodeAction().then((val) => {
                  FlowCtlData.formAlert(this, 'success')
                  this.$router.push('/ContractOrder/ContractList')
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    // 作废
    Abandoned () {
      const obj = {
        id: this.$route.query.id,
        status: 6
      }
      requestForm('/api/pact/contract/updateStatus', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.form = response.data
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push({
            path: '/ContractOrder/ContractList'
          })
        }
      })
    },
    ScopeChange: function () {
      this.form.companyCode = ''
      if (this.form.mySubject !== '') {
        request('/api/sap/getCompanyCodeName/' + this.form.mySubject, 'get').then((response) => {
          this.companyCodeList = response
        })
      }
    },
    companyChange: function (data) {
      for (var item of this.companyCodeList) {
        if (item.companyCode === data) {
          this.form.companyName = item.companyName
        }
      }
    },
    timeForm () {
      if (this.form.strStartDate) {
        this.form.strStartDate = dayjs(this.form.strStartDate).format('YYYY-MM-DD')
      } else {
        this.form.strStartDate = ''
      }
    },
    timeFormEnd () {
      if (this.form.strEndDate > new Date(this.form.strStartDate)) {
        if (this.form.strEndDate) {
          this.form.strEndDate = dayjs(this.form.strEndDate).format('YYYY-MM-DD')
        }
      } else {
        this.$message({
          message: '结束日期不能小于开始日期',
          type: 'error'
        })
        this.form.strEndDate = ''
      }
    },
    handlePreview (file) {
      exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.file.length; i++) {
        if (this.form.file[i].id === file.id) {
          this.form.file.splice(i, 1)
        }
      }
    },
    handleClose () {
      this.dialogShow = false // 关闭按钮
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.file.push(element)
        })
      }
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elform.scss';
</style>
